<template>
  <div class="order_wrap"></div>

  <Spinner v-show="processing" />
</template>

<script>
export default {
  name: "OrderPayMobile",
  metaInfo() {
    return {
      title: this.$t("meta.title") + " :: " + this.$t("meta.order"),
      og: {
        title: this.$t("meta.title") + " :: " + this.$t("meta.order"),
      },
    };
  },
  data: function () {
    return {
      process_queue: [],
    };
  },
  computed: {
    processing() {
      if (this.process_queue.length <= 0) return false;
      else return true;
    },
  },
  mounted() {
    this.parse_queries();
  },
  methods: {
    parse_queries: function () {
      if (!this.storage.is_logged_in()) {
        this.$router.push("/auth/login");
        return;
      }

      let self = this;
      let query = this.$route.query;

      // 전달 받은 쿼리 스트링 데이터에 담기
      let payment_data = {
        imp_uid: query.imp_uid,
        merchant_uid: query.merchant_uid,
        imp_success: query.imp_success,
        invoice_token: query.invoice_token,
        user_id: query.user_id,
        recipient_name: query.recipient_name,
        recipient_tel: query.recipient_tel,
        recipient_postalcode: query.recipient_postalcode,
        recipient_address: query.recipient_address,
        recipient_address_detail: query.recipient_address_detail,
        delivery_message: query.delivery_message,
        payment_point: query.payment_point,
        coupon_id: query.coupon_id,
        coupon_map_id: query.coupon_map_id,
        payment_method: query.payment_method,
      };

      // 결제 성공 여부 가져오기
      try {
        payment_data.imp_success = JSON.parse(
          payment_data.imp_success.toLowerCase().trim()
        );
      } catch (error) {
        payment_data.imp_success = false;
        console.log(error);
      }

      // 결제 실패인 경우
      if (!payment_data.imp_success) {
        self.$swal
          .fire({
            text: self.$t("order.error_fail_to_pay"),
            icon: "error",
          })
          .then(() => {
            if (
              typeof payment_data.invoice_token != "undefined" &&
              payment_data.invoice_token != ""
            ) {
              self.$router.push("/");
            } else {
              self.$router.push("/order/" + payment_data.invoice_token);
            }
          });
        return;
      }

      // 결제 처리하기
      this.process_pay(payment_data);
    },

    // PG 결제 성공 후 처리
    process_pay: function (payment_data) {
      if (!this.storage.is_logged_in()) {
        this.$router.push("/auth/login");
        return;
      }

      let self = this;
      self.process_queue.push(1);

      // 결제 검증
      this.axios
        .post(process.env.VUE_APP_API_URL + "/order/pay", payment_data, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then(function (response) {
          let data = response.data;

          if (data.result != "OK") {
            self.$swal
              .fire({
                text: self.$t("common.error_occur"),
                icon: "error",
              })
              .then(() => {
                if (
                  typeof payment_data.invoice_token != "undefined" &&
                  payment_data.invoice_token != ""
                ) {
                  self.$router.push("/");
                } else {
                  self.$router.push("/order/" + payment_data.invoice_token);
                }
              });
            return;
          }

          let order_id = 0;
          if (typeof data.data.order_id != "undefined") {
            order_id = data.data.order_id;
          }

          let route_arg = {
            path: "/order/complete",
            query: { order_id: order_id },
          };
          self.$router.push(route_arg);
        })
        .finally(function () {
          self.process_queue.pop();
        }); //-- axios
    }, //-- callback_pay
  },
};
</script>

<style scoped>
.order_wrap {
}
</style>
